<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="Inviter utilisateur"
    placement="top"
  >
    <el-button
      type="primary"
      :icon="PlusIcon"
      plain
      @click="dialogFormAdd = true"
      >Ajouter</el-button
    >
  </el-tooltip>
  <el-dialog
    title="Ajout d'un revendeur "
    v-model="dialogFormAdd"
    append-to-body
    center
    width="30%"
  >
    <el-form ref="retailerForm" :model="form" :rules="rules">
      <el-form-item label="Nom " prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormAdd = false" type="danger" plain
          >Annuler</el-button
        >
        <el-button type="primary" @click="create('retailerForm')"
          >Ajouter</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialogFormAdd: false,
      formLabelWidth: '150px',
      form: {
        name: null,
      },
      confirmPassword: null,
      rules: {
        name: [
          {
            required: true,
            message: 'Veuillez saisir le nom',
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      token: 'auth/token',
      member: 'auth/member',
      users: 'users/users',
      organisation: 'organisations/organisation',
    }),
  },
  methods: {
    ...mapActions({
      addUser: 'users/addUser',
    }),
    create(retailerForm) {
      this.$refs[retailerForm].validate((valid) => {
        if (valid) {
          let payload = {
            data: {
              ...this.form,
            },
          }
          this.$store
            .dispatch('retailers/addRetailer', payload)
            .then(() => {
              this.dialogFormAdd = false
            })
            .then(() => {
              this.form.name = ''
            })
        } else return false
      })
    },
  },
}
</script>

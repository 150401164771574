<template>
  <el-row type="flex" justify="space-between">
    <el-col :span="4">
      <div class="title-container">
      <span>Revendeurs</span>
      <b v-if="retailers.length > 0" class="count">{{ retailers.length }}</b>
    </div>
    </el-col>
    <el-col :span="2" :offset="0"> <AddRetailer /></el-col>
  </el-row>
  <el-table stripe :data="retailers">
    <el-table-column label="Nom" prop="name"> </el-table-column>
 
    <el-table-column align="right" label="Action" width="150"  >
      <template #default="scope">
        <div style="margin-left: auto">
          <el-space>
            <el-tooltip placement="left">
              <template #content> Changer statut</template>
              <el-switch
                v-model="scope.row.enabled"
                :disabled="!isAppAdmin"
                @change="enableRetailer(scope.row)"
              />
            </el-tooltip>
            <edit-retailer :index="scope.$index" :row="scope.row" />
            <el-popconfirm
              confirmButtonText="Oui"
              cancelButtonText="Annuler"
              @confirm="handleDelete(scope.$index, scope.row)"
              :icon="InfoIcon"
              iconColor="#F56C6C"
              title="Voulez vous vraiment supprimer cette organisation ?"
            >
              <template #reference>
                <el-button
                  size="small"
                  type="danger"
                  :icon="DeleteIcon"
                ></el-button>
              </template>
            </el-popconfirm>
          </el-space>        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-row type="flex" class="row-bg" justify="center" v-if="metadata">
    <el-pagination
      :current-page="page"
      layout="total, prev, pager, next, jumper"
      :total="metadata.totalElements"
      :page-size="20"
      @current-change="setPage"
    >
    </el-pagination>
  </el-row>
</template>

<script>
import AddRetailer from './AddRetailer.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import EditRetailer from './EditRetailer.vue'
import _ from 'lodash'
export default {
  
  components: { AddRetailer, EditRetailer },
  data() {
    return {
      search: '',
      searchInvi: '',
      dialogFormVisible: false,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      roleOptions: [
        { value: 'RETAILER_ADMIN', label: 'RETAILER_ADMIN' },
        { value: 'RETAILER_MANAGER', label: 'RETAILER_MANAGER' },
        { value: 'APP_USER', label: 'APP_USER' },
        { value: 'APP_ADMIN', label: 'APP_ADMIN' },
      ],
      formLabelWidth: '150px',
      optionWidth: '100px',
    }
  },
  computed: {
    ...mapGetters({
      isAppAdmin: 'auth/isAppAdmin',
    }),
    ...mapState('retailers', ['retailers', 'metadata']),
    page() {
      return Number(_.get(this.metadata, 'pageable.pageNumber', 0)) + 1
    },
    totalElements() {
      return _.get(this.metadata, 'totalElements', '0')
    },

    
  },
  methods: {
    
    ...mapActions({
      setRetailer: 'retailers/setRetailer',
      setIndex: 'retailers/setIndex',
      deleteRetailer: 'retailers/deleteRetailer',
      editRetailerApi: 'retailers/editRetailerApi',
    }),
    handleDelete(index, row) {
      this.$store.dispatch('retailers/deleteRetailer', { id: row.id })
    },
    handleSearch() {
      let payload = {
        params: {
          q: this.search,
        },
      }
      this.$store.dispatch('retailers/getRetailers', payload)
    },
    enableRetailer(row) {
      let payload = {
        id: row.id,
        data: {
          enabled: row.enabled,
        },
      }
      this.$store.dispatch('retailers/updateRetailer', payload)
    },
    updateRole(row) {
      let payload = {
        id: row.id,
        data: {
          appRole: row.appRole,
        },
      }
      this.$store.dispatch('retailers/updateRetailer', payload)
    },
    updateRetailer() {
      var data = {
        expires_at: this.usertoEdit.attributes.attributes.expires_at,
        id: this.usertoEdit.attributes.id,
        index: this.index,
        organization_id: this.organisation.id,
        role: this.form.role,
        token: this.token,
      }

      this.editRetailerApi(data)
        .then((response) => {
          this.$notify({
            title: 'Success',
            message: response.data,
            type: 'success',
            offset: 100,
          })
          this.dialogFormVisible = false
          location.reload()
        })
        .catch((error) => console.log(error))
    },
    handleEdit(index, row) {
      this.setRetailer(row)
      this.setIndex(index)
      this.dialogFormVisible = true
    },

    setPage(val) {
      let payload = {
        params: {
          page: val - 1,
        },
      }
      this.$store.dispatch('retailers/getRetailers', payload)
    },

    
  },
}
</script>

<style>
.cell {
  display: flex;
  align-items: center;
}
.user-avatar {
  background-color: #042055;
}
form label {
  font-weight: bold;
}
.title-container {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }
  .title-container span:first-child {
    margin-right: 5px;
  }
  .title-container .count {
    font-size: 16px;
    font-weight: normal;
    margin-left: 5px;
    color: #666;
  }
</style>

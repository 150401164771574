<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="Inviter utilisateur"
    placement="top"
  >
    <el-button :icon="EditIcon" @click="handleEdit(index, row)"></el-button>
  </el-tooltip>
  <el-dialog
    title="Modification "
    v-model="dialogFormEdit"
    append-to-body
    center
    width="30%"
  >
    <el-form ref="retailerForm" :model="form" :rules="rules">
      <el-form-item label="Nom " prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormEdit = false" type="danger" plain
          >Annuler</el-button
        >
        <el-button type="primary" @click="update('retailerForm')"
          >Confirmer</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['index', 'row'],
  data() {
    return {
      dialogFormEdit: false,
      formLabelWidth: '150px',
      form: {
        id: null,
        name: null,
      },
      confirmPassword: null,
      rules: {
        name: [
          {
            required: true,
            message: 'Veuillez saisir le prénom',
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      token: 'auth/token',
      member: 'auth/member',
      users: 'users/users',
      organisation: 'organisations/organisation',
    }),
  },
  methods: {
    update(retailerForm) {
      this.$refs[retailerForm].validate((valid) => {
        if (valid) {
          let payload = {
            id: this.form.id,
            data: {
              name: this.form.name,
            },
          }

          this.$store.dispatch('retailers/updateRetailer', payload).then(() => {
            this.dialogFormEdit = false
          })
        } else return false
      })
    },
    handleEdit(index, row) {
      this.dialogFormEdit = true
      this.populate(row)
    },
    populate(row) {
      this.form.id = row.id
      this.form.name = row.name || null
    },
  },
}
</script>

<template>
  <div style="margin-top: 10px">
    <RetailersTable />
  </div>
</template>

<script>
import RetailersTable from './components/RetailersTable.vue'
export default {
  components: { RetailersTable },
  data() {
    return {}
  },
  computed: {},
  mounted() {
    this.$store.dispatch('retailers/getRetailers', {})
  },
  methods: {},
}
</script>

<style></style>
